import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  const toast = useToast()

  const refPaySlipsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Uitbetalingen', key: 'show_details', sortable: true },
    { label: 'Loonstrooknummer', key: 'id', sortable: true },
    { label: 'Datum', key: 'timestamp', sortable: true },
    { label: 'verloningsperiode', key: 'period', sortable: true },
    { label: 'Netto bedrag', key: 'netPay', sortable: true },
    { label: 'Reeds uitbetaald', key: 'paidAmount', sortable: true },
    { label: 'Acties', key: 'actions' },
  ]

  const tablePayoutsColumns = [
    { label: 'Betaal-ID', key: 'paycheckID', sortable: false },
    { label: 'Uitbetaaldatum', key: 'paymentTimestamp', sortable: false },
    { label: 'Bedrag', key: 'amount', sortable: false },
    { label: 'Status', key: 'status', sortable: false },
    { label: 'IBAN', key: 'iban', sortable: false },
  ]

  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(false)
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refPaySlipsListTable.value ? refPaySlipsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refPaySlipsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchPayslips = (ctx, callback) => {
    store
      .dispatch('app-payslips/fetchPayslips', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        total.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen facturen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
          },
        })
      })
  }

  const resolvePayOutStatusVariant = status => {
    if (status === 1) return { status: 'In wachtrij', variant: 'warning' }
    if (status === 2) return { status: 'Betaald', variant: 'success' }
    return { status: 'In wachtrij', variant: 'warning' }
  }

  return {
    refPaySlipsListTable,
    dataMeta,
    tableColumns,
    perPage,
    total,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    showOverlay,
    searchQuery,
    refetchData,
    fetchPayslips,
    tablePayoutsColumns,
    resolvePayOutStatusVariant,
  }
}
